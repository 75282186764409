import { useRouter } from 'next/router';
import { PropsWithChildren, ReactElement } from 'react';
import { StaticRouter, BrowserRouter } from 'react-router-dom';

type Props = PropsWithChildren<{}>;
export function Router({ children }: Props) {
  const router = useRouter();

  let component: ReactElement;
  if (typeof window === 'undefined') {
    component = (
      <StaticRouter basename={router.basePath} location={router.asPath}>
        {children}
      </StaticRouter>
    );
  } else {
    component = <BrowserRouter basename={router.basePath}>{children}</BrowserRouter>;
  }

  return <div suppressHydrationWarning>{component}</div>;
}

export default Router;
