import { Route, RouteProps } from 'react-router';
import loadable, { DefaultComponent, OptionsWithoutResolver } from '@loadable/component';

type LoadableRouteProps = Omit<RouteProps, 'component' | 'render' | 'children'> &
  Pick<OptionsWithoutResolver<{}>, 'fallback'> & {
    component: (props: {}) => Promise<DefaultComponent<{}>>;
  };

export function LoadableRoute({ fallback, component, ...routeProps }: LoadableRouteProps) {
  const LoadableComponent = loadable(component, {
    fallback,
  });

  return <Route {...routeProps} render={() => <LoadableComponent />} />;
}

export default LoadableRoute;
