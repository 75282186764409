import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';

import { RouteConfig, ExtraProps, RoutesProps } from './types';
import { renderRoutes } from './helper';

/**
 * Routes
 */
export function Routes<T extends RouteConfig, E extends ExtraProps = {}>({
  routes,
  extraProps,
  default: defaultComponent,
  notFond,
  switchProps,
  routeRender,
}: RoutesProps<T, E>) {
  const { path: matchPath } = useRouteMatch();
  if (!routes) return null;

  return (
    <Switch {...switchProps}>
      {renderRoutes(routes, { routeRender, extraProps, matchPath })}

      {defaultComponent && (
        <Route path={matchPath} exact>
          {defaultComponent}
        </Route>
      )}

      {notFond && <Route path="*">{notFond}</Route>}
    </Switch>
  );
}

export default Routes;
