import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';

import { useStore } from '@my/mobx-rootstore';
import { AuthStore } from '@/stores/modules/auth/AuthStore';
// router
import { Redirect } from 'react-router';
import { Router } from '@/components/Router';
import { Routes } from '@components/react-router';
import { SyncRouterStore } from '@my/mobx-rootstore-router';
import { routes } from './routes';

const IndexPage: FC = () => {
  return (
    <Router>
      <SyncRouterStore />
      <Routes routes={routes} default={<HomeRedirect />} notFond={<div>404</div>} />
    </Router>
  );
};

const HomeRedirect: FC = observer(() => {
  const authStore = useStore(AuthStore);
  const router = useRouter();
  useEffect(() => {
    if (!authStore.loginSession) {
      router.replace('/login');
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return authStore.loginSession ? <Redirect to="/workspace" /> : null;
});

export default IndexPage;
