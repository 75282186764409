import { useLocation, useHistory, LinkProps } from 'react-router-dom';
import { createLocation, LocationDescriptor } from 'history';
import queryString from 'query-string';

export type History = ReturnType<typeof useHistory>;
export type Location = ReturnType<typeof useLocation>;
// export type LocationDescriptor = Parameters<History['replace']>[0];

export interface LocationParams {
  pathname?: string;
  search?: string;
  state?: any;
  hash?: string;
  key?: string;
  query?: Record<string, any>;
}
export type NativateParams = LocationParams | string;

export function useQuery() {
  const location = useLocation();

  const setQuery = useSetQuery();

  const queryObj = toQueryObject(location.search);

  return [queryObj, setQuery] as const;
}

export function useSetQuery() {
  const history = useHistory();
  return function setQuery(query: Record<string, any>) {
    const search = toQueryString(query);
    history.replace({
      ...location,
      search,
    });
  };
}

/**
 * replace
 *
 * 与 history.replace 基本一样，多了 query 参数
 */
export function replace(history: History, params: NativateParams) {
  return history.replace(typeof params === 'string' ? params : toLocation(params));
}

/**
 * push
 *
 * 与 history.push 基本一样，多了 query 参数
 */
export function push(history: History, params: NativateParams) {
  return history.push(typeof params === 'string' ? params : toLocation(params));
}

export function toQueryString(queryObject: Record<string, any>) {
  return queryString.stringify(queryObject, { arrayFormat: 'bracket' });
}

export function toQueryObject(query?: string) {
  return query ? queryString.parse(query, { parseBooleans: true, parseNumbers: true, arrayFormat: 'bracket' }) : {};
}

export function toLocation({ query, search, ...restParams }: LocationParams) {
  const searchStr = query ? toQueryString(query) : search;
  return {
    ...restParams,
    search: searchStr,
  };
}
export const resolveToLocation = (to: LinkProps['to'], currentLocation: Location) =>
  typeof to === 'function' ? to(currentLocation) : to;

export const normalizeToLocation = (to: LocationDescriptor, currentLocation: Location) => {
  return typeof to === 'string' ? createLocation(to, undefined, undefined, currentLocation) : to;
};
