import { useStore } from '@my/mobx-rootstore';
import { replace, push, NativateParams, History, Location, toQueryObject } from '@my/router';
import { useRef } from 'react';
import { makeAutoObservable } from 'mobx';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * useSync
 */
export function useSync() {
  const routerStore = useStore(RouterStore);

  const history = useHistory();
  const historyRef = useRef<History>();
  if (!historyRef.current || historyRef.current !== history) {
    historyRef.current = history;
    routerStore.setHistory(history);
  }

  const location = useLocation();
  const locationRef = useRef<Location>();
  if (!locationRef.current || locationRef.current !== location) {
    locationRef.current = location;
    routerStore.setLocation(location);
  }

  const searchRef = useRef<string>();

  if (searchRef.current !== location.search) {
    routerStore.setQuery(toQueryObject(location.search));
  }

  return routerStore;
}

/**
 * SyncRouterStore
 */
export function SyncRouterStore() {
  useSync();
  return null;
}

/**
 * RouterStore
 */
export class RouterStore {
  /**
   * query
   */
  query: Record<string, any> = {};

  private _history?: History;
  private _location?: Location;

  constructor() {
    makeAutoObservable(this);
  }

  get history() {
    if (process.env.NODE_ENV === 'development') {
      if (!this._history) throw new Error('请先使用 useSync 进行同步');
    }
    return this._history!;
  }

  get location() {
    if (process.env.NODE_ENV === 'development') {
      if (!this._location) throw new Error('请先使用 useSync 进行同步');
    }
    return this._location!;
  }

  setHistory(history: RouterStore['history']) {
    this._history = history;
  }

  setLocation(location: RouterStore['location']) {
    this._location = location;
  }

  setQuery(query: RouterStore['query']) {
    this.query = query;
  }

  replace(params: NativateParams) {
    return replace(this.history, params);
  }

  push(params: NativateParams) {
    return push(this.history, params);
  }
}

export default RouterStore;
