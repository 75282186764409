import dynamic from 'next/dynamic';
import { RouteConfig } from '@components/react-router';

export const routes: RouteConfig[] = [
  {
    path: '/workspace',
    component: dynamic(() => import('./workspace'), {
      ssr: false,
    }),
  },
];
