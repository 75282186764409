import { ReactNode } from 'react';
import { Route } from 'react-router-dom';
import { RouteConfig, RouteComponentProps, ExtraProps, RouteRenderParams } from './types';

/**
 * render
 */
export function render(route: RouteConfig, componentProps: RouteComponentProps, extraProps?: ExtraProps) {
  return route.render ? (
    route.render(componentProps, extraProps ?? {})
  ) : route.component ? (
    <route.component route={route} {...componentProps} {...extraProps} />
  ) : null;
}
export type Render = typeof render;

/**
 * 渲染单个 Route
 */
export function renderRoute<T extends RouteConfig, E extends ExtraProps = {}>(
  route: T,
  { extraProps, matchPath }: { extraProps?: E; matchPath?: string },
) {
  // path 优先于 extraProps
  let path: string | readonly string[] | undefined;
  if (route.path !== undefined) {
    path = route.path;
  } else if (route.relativePath !== undefined) {
    path = matchPath ? `${matchPath}${route.relativePath}` : route.relativePath;
  }
  return (
    <Route
      key={route.key}
      path={path}
      exact={route.exact}
      strict={route.strict}
      render={(componentProps) => render(route, componentProps, extraProps)}
    />
  );
}

/**
 * 渲染多个 Route
 */
export function renderRoutes<T extends RouteConfig, E extends ExtraProps = {}>(
  routes: T[],
  {
    matchPath,
    extraProps,
    routeRender,
  }: {
    matchPath?: string;
    extraProps?: E;
    routeRender?: (params: RouteRenderParams<T, E>) => ReactNode;
  } = {},
) {
  return routes.map((route, i) =>
    renderRoute(
      {
        ...route,
        key: route.key ?? i,
        render: routeRender
          ? (componentProps, extraProps) => routeRender({ route, props: componentProps, extraProps, render })
          : route.render,
      },
      { extraProps, matchPath },
    ),
  );
}
